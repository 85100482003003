@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base{
  @font-face {
    font-family: 'Eau';
    font-style:normal; 
    font-weight: 400;
    src: url('/public/fonts/eau/eau_sans_black_obl.ttf') format('truetype');
  }
  @font-face {
    font-family: 'Source';
    font-style:normal; 
    font-weight: 400;
    src: url('/public/fonts/Source_Sans_3/SourceSans3-VariableFont_wght.ttf') format('truetype');
  }
  @font-face {
    font-family: 'Cedarville';
    font-style:normal; 
    font-weight: 400;
    src: url('/public/fonts/Cedarville_Cursive/CedarvilleCursive-Regular.ttf') format('truetype');
  }
  @font-face {
    font-family: 'Antonio';
    font-style:normal;
    font-weight: 400; 
    src: url('/public/fonts/Antonio/Antonio-VariableFont_wght.ttf') format('truetype'); 
  }
  @font-face{
    font-family: 'Roboto';
    font-style:normal;
    font-weight:400;
    src: url('/public/fonts/Roboto_Condensed/RobotoCondensed-VariableFont_wght.ttf') format('truetype'); 
  }
  @font-face{
    font-family: 'Knewave';
    font-style:normal;
    font-weight:400;
    src: url('/public/fonts/Knewave/Knewave-Regular.ttf') format('truetype'); 
  }

  @keyframes marquee {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  
  .animate-marquee {
    animation: marquee 20s linear infinite;
  }
  
  @media (max-width: 640px) {
    .text-67 {
      font-size: 40px;
    }
    
    .text-80 {
      font-size: 48px;
    }
    
    .w-20, .h-20 {
      width: 60px;
      height: 60px;
    }
  
    .text-4xl {
      font-size: 24px;
    }
  }
}


